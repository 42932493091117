// SPINNER QTY 

.c-spinner {
	width: 100%;

	input{
		float:left;
		width:40px;
		padding:6px;
		border-radius: 0;
		border-color:#d0d7de;
	}
}

.c-input-group-btn-vertical {
	position: relative;
	white-space: nowrap;
	width: 1%;
	vertical-align: middle;
	display: inline-block;

	i{
		position: absolute;
		top: 0;
		left: 4px;
	}

	.btn {
		display: block;
		float: none;
		max-width: 100%;
		padding: 8px;
		margin-left: -1px;
		position: relative;
		border-radius: 0;
		color:#d0d7de;
		border-color:#d0d7de;

		&:first-child{
			border-top-right-radius: 0;
		}

		&:last-child{
			margin-top: -2px;
			border-bottom-right-radius: 0;
		}
	}
}