// PRODUCT GALLERY - MINIMALIST GALLERY

.simplegallery {
    margin: 0 auto;
    width: 417px;
    height: 380px;
    overflow: hidden;

    .content {
        background: #fff;
        position: relative;
        width: 418px;
        height: 270px;
        overflow: hidden;

        img {
            width: 415px;
            border: 1px solid #9c9c9c;
        }
    }

    .thumbnail { 
        margin-top: 5px; 

        .thumb {
            float: left;
            width: 100px;
            height: 100px;
            margin: 0 5px 0 0;
            cursor: pointer;

            img {
                border: 1px solid #9c9c9c;
                width: 100px;
            }
        }

        .last { 
            margin: 0; 
        }
    }
}