.table > tfooter > tr > td,
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > thead > tr > th {
  border-color: $general-border-color;
}

.table > thead > tr > th {
	font-weight: 500;
	font-size: 17px; 
	color: $general-head-font-color;
}

.table tr {
	&.success,
	&.info,
	&.danger,
	&.warning {
		> th,
		> td {
			color: #fff;
		}
	}
}

.table-responsive {
	border-color: $general-border-color;

}