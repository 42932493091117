// cubeportfolio main container
.cbp-panel {
 	width: 100%;
  	max-width: auto;
  	margin: 0 auto;
  	font-family: $general-font-family !important;
}

.cbp-popup-wrap {
	z-index: $zindex-navbar + 10;
}

.cbp-l-caption-title {
	font-family: $general-font-family;
	text-transform: uppercase;
  	font-size: 17px;
  	font-weight: 500;
  	margin: 0;
}

.cbp-l-caption-desc {
	font-size: 15px;
	font-family: $general-font-family;
}

.cbp-caption-activeWrap {
	background: #000000;
}

.cbp-l-inline-desc {
  font-size: 17px;
  font-family: $general-font-family;
  margin-bottom: 10px;
  font-weight: 300;
  color: $general-head-font-color;
}

.cbp-l-filters-dropdownWrap {
	background: none;
	border: 1px solid $general-head-font-color;

	.cbp-l-filters-dropdownHeader {
		color: $general-head-font-color;
		font-size: 16px;
	}

	.cbp-l-filters-dropdownList {
		background: #ffffff;

		.cbp-filter-item {
			font-size: 16px;
			color: $general-head-font-color;
			border: 0;

			&:hover {
				background: lighten($general-head-font-color, 75%);
			} 

			&.cbp-filter-item-active {
				background: lighten($general-head-font-color, 70%);
			}
		}
	}
}

// full width mode

.c-content-box > .cbp {
	margin-right: -2px;
}

.cbp img {
	border: 1px solid #e2e2e2;
}

.cbp-l-inline-view {
	margin-top: 20px;
}

// filter buttons
.cbp-l-filters-work,
.cbp-l-filters-button,
.cbp-l-filters-buttonCenter {

	.cbp-filter-item {
		border-radius: 0;
		font-family: $general-font-family;
	  	background-color: #FFFFFF;  
	  	border: 1px solid $general-head-font-color;
	  	color: $general-head-font-color;
	  	cursor: pointer;
	  	font-size: 14px;
	  	font-weight: 400;
	  	padding: 0px 12px;
		
		&:hover {
		  	background-color: $general-head-font-color;
		  	color: #fff;
		}

		&.cbp-filter-item-active {
			background-color: $general-head-font-color;
		  	color: #fff;
		  	border: 1px solid $general-head-font-color;
		}
	}
}

.cbp-l-filters-work {
	.cbp-filter-counter {
		background: none;
		display: inline-block;
		top: -1px;  
		position: relative;

		&:before {
			border: 0;
		}
	}
}

// titles

.cbp-l-inline-title {
	font-family: $general-font-family;
	text-transform: uppercase;
  	font-size: 24px;
  	font-weight: 600;
  	margin: 0;
  	color: $general-head-font-color;
}

.cbp-l-inline-subtitle {
	font-family: $general-font-family;
	text-transform: uppercase;
  	font-size: 14px;
  	font-weight: 400;
  	color: $general-head-font-color;
  	margin-top: 0px;
}

.cbp-l-grid-masonry-projects-desc {
	font-family: $general-font-family;
	margin-top: 2px;
	font-size: 13px;
	color: lighten($general-head-font-color, 25%);
}

.cbp-l-caption-buttonLeft,
.cbp-l-caption-buttonRight {
	padding-left: 10px;
	padding-right: 10px;
	min-width: 50px;
}

.cbp-l-grid-masonry-projects .cbp-caption-activeWrap {
  background-color: #000000;
  background-color: rgba(#000000, 0.6);   
}

// Popup navigation wrap
.cbp-popup-singlePage .cbp-popup-navigation-wrap {
	z-index: $zindex-navbar + 11; 
	background-color: #000000;
  	background-color: rgba(#000000, 0.8);
}

// Project detauls


.cbp-l-grid-masonry-projects-title {
	color: $general-head-font-color;
  	font-family: $general-font-family;
	text-transform: uppercase;
  	font-size: 17px;
  	font-weight: 600;   
}

.cbp-l-grid-masonry-projects-desc {
	color: $general-font-color;
  	font-family: $general-font-family;
  	font-size: 16px;
  	font-weight: 300;
}

.cbp-l-project-title {
  	color: $general-head-font-color;
  	font-family: $general-font-family;
	text-transform: uppercase;
  	font-size: 38px;
  	font-weight: 600;
  	letter-spacing: 1px;  
}
 
.cbp-l-project-subtitle {
	font-family: $general-font-family;
	margin-top: 2px;
	font-size: 13px;
	color: lighten($general-head-font-color, 15%);
}

.cbp-l-project-related-link {
	color: $general-head-font-color;
  	font-family: $general-font-family;
	text-transform: uppercase;
  	font-size: 17px;
}

.cbp-l-project-desc-title span, 
.cbp-l-project-details-title span {
	font-family: $general-font-family;
	display: inline-block;
	color: $general-head-font-color;
	text-transform: uppercase;
	font-size: 18px;
  	font-weight: 600;
  	border-bottom: 0;
  	margin: 5px 0 -1px 0;
  	padding: 0 5px 0 0;
 }

 .cbp-l-project-desc-title,
 .cbp-l-project-details-title {
 	margin-bottom: 40px;
 	border-bottom: 0;
 	display: block;
  	position: relative;
  	overflow: visible;

 	&:before {
	  	position: absolute;
	  	top: 50px;
	  	content: '';
	  	width: 20px;
	  	height: 3px;
	  	display: block;
	}
 }

.cbp-l-project-desc-text {
	font-family: $general-font-family;
  	color: lighten($general-head-font-color, 15%);
	font-size: 17px;
  	font-weight: 300;
  	margin-bottom: 10px;
}

 .cbp-l-project-details-title {
 	margin-bottom: 10px;
 }

.cbp-l-project-details-list {
	list-style: none;
	margin: 35px 0 20px 0;
	padding: 0;

	> li {
		font-family: $general-font-family;
		padding: 5px 0;
		margin: 0;
		color: lighten($general-head-font-color, 20%);
		font-size: 16px;
		font-weight: 300;

		&:first-child {
			padding-top: 0;
		}

		> strong {
			color: lighten($general-head-font-color, 5%);
			font-family: $general-font-family;
			font-size: 16px;
		  	font-weight: 500;
		}
	}
}

.cbp-l-project-container {
	margin: 40px 0;
}

.cbp-l-project-related-wrap {
	padding: 0;
	margin: 0;
}

.cbp-l-project-related-title {
	font-family: $general-font-family; 
  	color: lighten($general-head-font-color, 15%);
	font-size: 16px;
  	font-weight: 600;
}

//
.cbp-l-grid-work-title,
.cbp-l-grid-projects-title,
.cbp-l-grid-agency-title {
	font-family: $general-font-family;
	color: $general-head-font-color;
	font-size: 18px;
  	font-weight: 600;
}

.cbp-l-grid-work-desc,
.cbp-l-grid-projects-desc,
.cbp-l-grid-agency-desc {
	font-family: $general-font-family;
	font-size: 13px;
	color: lighten($general-head-font-color, 25%);
}

// Text filters

.cbp-l-filters-alignCenter,
.cbp-l-filters-text {
	font-family: $general-font-family;

	.cbp-l-filters-text-sort {
		font-family: $general-font-family;
		font-size: 14px;
		font-weight: 400;
		color: lighten($general-head-font-color, 15%);
	}

	.cbp-filter-item {
		text-transform: uppercase;
		font-family: $general-font-family;
		font-size: 16px;
		letter-spacing: 1px;
		font-weight: 600;
		color: lighten($general-head-font-color, 10%);
	}
}


.cbp-popup-lightbox-title {
	color: #eee;
  	font-family: $general-font-family;
	font-size: 16px;
  	font-weight: 300;
}

// FAQ

.cbp-l-filters-underline .cbp-filter-item.cbp-filter-item-active {
	font-family: $general-font-family;
	font-size: 18px;
  	font-weight: 600;
  	cursor: default;
}

.cbp-l-filters-underline .cbp-filter-item {
	font-family: $general-font-family;
	font-size: 18px;
  	font-weight: 600;
  	border-bottom: 3px solid transparent;
  	color: $general-head-font-color;
}

.cbp-caption-expand {
	.cbp-caption-defaultWrap {
		font-family: $general-font-family;
		font-size: 18px;
  		font-weight: 400;
  		color: $general-head-font-color; 

		.fa {
  			margin-right: 6px;
  			width: 20px;
  			text-align: center;  
  			font-size: 20px;  
  			color: #cad5e0;   
		}
	}

	.cbp-l-caption-body {
		font-family: $general-font-family;
		font-size: 15px;
  		font-weight: 400;
  		color: $general-head-font-color;
	}
}

// START : Custom Oswald Hover

.cbp-l-grid-masonry-projects .cbp-l-caption-body a {
	font-family: $general-font-family; 
	line-height:16px;
	font-size:12px;
	font-weight:4600;
}

.cbp-caption-activeWrap .c-masonry-border{
	border: 1px solid rgba(255,255,255,0.2);
  	top: 10px;
  	bottom: 10px;
  	left: 10px;
  	right: 10px;
  	position: absolute;
}

// END : Custom Oswald Hover
