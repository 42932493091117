/* Left and right tabs */

.tabs-right.nav-tabs, 
.tabs-left.nav-tabs {
    border-bottom: 0;  

    > li {
        float: none;

        > a {
            margin-right: 0;
            margin-bottom: 3px;
        }
    }  
}

/* Left tabs */
    
.tabs-left.nav-tabs {
  border-right: 1px solid #ddd;

    > li > a {
        display: block;
        margin-right: -1px;
    
        &:hover,  
        &:focus {
            border-color: #eeeeee #dddddd #eeeeee #eeeeee; 
        }
    }

    > li.active > a,
    > li.active > a:hover
    > li.active > a:focus {      
        border-color: #ddd transparent #ddd #ddd;      
        *border-right-color: #ffffff;   
    }
}

/* Right tabs */

.tabs-right.nav-tabs {
    border-left: 1px solid #ddd;

    > li > a {
        display: block;
        margin-left: -1px;
    
        &:hover,
        &:focus {
            border-color: #eeeeee #eeeeee #eeeeee #dddddd;
        }
    }

    > li.active > a,
    > li.active > a:hover
    > li.active > a:focus {
        border-color: #ddd #ddd #ddd transparent;
        *border-left-color: #ffffff;
    }
}
