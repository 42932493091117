// Bootstrap Dropdown Menu

.dropdown-menu {
	border: 0;
    box-shadow: 0px 3px 12px -5px rgba(0,0,0,0.35);

    > li > a {
        font-size: 16px;
        color: $general-font-color;
    }
}

/* Dropdown submenu support for Bootsrap 3 */
.dropdown-submenu {
    position: relative;

    > .dropdown-menu {
        top: 5px;
        left: 100%;
        margin-top: -6px;
        margin-left: 1px;
    	
    	&.c-pull-left {
            left: -100%;
            margin-left: -1px;
        }
    }

    &:hover > .dropdown-menu {
        display: block;
    }
  
    // dropdown menu in dropup mode
    .dropup & > .dropdown-menu {
        top: auto;
        bottom: 0;
        margin-top: 0;
        margin-bottom: -2px;
    }
}